<template>
  <!-- App.vue -->
  <v-app>
    <!-- menu -->
    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
        >
          <v-list-item to="/">
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>

          <v-list-item to="/tienda">
            <v-list-item-title>Tienda</v-list-item-title>
          </v-list-item>

          <v-list-item to="/carrito">
            <v-list-item-title>Mi carrito</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    
    <!-- cart -->
    <v-navigation-drawer
      app
      v-model="drawerCart"
      temporary
      right
      width="340"
      light
    >
      <div class="d-flex align-center justify-space-between py-3 px-4">
        <span class="text-h6 black--text">Carrito de compras</span>
        <v-btn icon @click="drawerCart=!drawerCart">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div v-if="JSON.stringify(cart)!='{}'">
        <v-list three-line>
          <template v-for="(item, j) in cart">
            <v-list-item :key="j">
              <v-badge
                :content="item.quantity"
                :value="item.quantity"
                color="orange darken-2"
                overlap
              >
                <v-avatar size="60" tile>
                  <v-img :src="item.img" contain></v-img>
                </v-avatar>
              </v-badge>
              <!-- <v-list-item-avatar tile width="60" height="60">
                <v-img :src="item.img"></v-img>
              </v-list-item-avatar> -->

              <v-list-item-content class="ml-4">
                <v-list-item-title class="font-weight-light text-h6">{{ item.name }} <small v-if="item.complement!=''">({{ item.complement }})</small></v-list-item-title>
                <v-list-item-subtitle class="red--text text--darken-4">{{ item.price }}/Bs</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-thin grey--text text--lighten-1">{{ item.category }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon small @click="$store.dispatch('cart/removeItem', j)">
                <!-- <v-btn icon small @click="$store.dispatch('cart/removeItem', item.id)"> -->
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider :key="j+250"></v-divider>
          </template>
        </v-list>

        <div class="px-4 pt-10 pb-4">
          <v-row>
            <v-col cols="12" class="text-right d-flex align-center justify-end">
              <span class="text-body-2 grey--text text--lighten-1 font-weight-thin"> Subtotal: ({{ count }} items): </span>
              <div class="red--text headline text--darken-4 font-weight-thin">&nbsp;{{ total.toFixed(2) }}/Bs</div>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                class="mb-3"
                to="/carrito"
              >Ver carrito</v-btn>
              <v-btn
                block
                dark
                to="/finalizar-compra"
              >Finalizar compra</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="pa-4" v-else>
        <div class="text-center mt-5 mb-10">
          <v-icon>mdi-cart</v-icon>
          <div class="grey--text text--darken-1 mt-5 font-weight-thin">Carrito de compras vacío</div>
        </div>
        <v-btn
          color="error"
          block
          to="/tienda"
        >Seguir comprando</v-btn>
      </div>

    </v-navigation-drawer>

    <v-app-bar
      app
      elevate-on-scroll
      :height="($vuetify.breakpoint.mdAndUp)?100:80"
      class="px-md-10"
      :class="classNavbarBg"
    >
      <!-- -->
      <!-- <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon> -->
      <v-btn icon :x-large="$vuetify.breakpoint.smAndUp" @click.stop="drawer=!drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <!-- mode dark -->
      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="mr-0"
          >
            <v-icon>mdi-{{ ($vuetify.theme.dark)?'moon-waning-crescent':'white-balance-sunny' }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="changeMode">
            <v-icon>mdi-white-balance-sunny</v-icon>
            <v-list-item-title>Tema claro</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="changeMode">
            <v-icon>mdi-moon-waning-crescent</v-icon>
            <v-list-item-title>Tema oscuro</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-spacer></v-spacer>
      <v-toolbar-title class="ml-12">
        <router-link to="/">
          <v-img
            v-if="logo!=''"
            :lazy-src="logo"
            :max-height="($vuetify.breakpoint.smAndUp)?60:50"
            :max-width="($vuetify.breakpoint.smAndUp)?250:200"
            contain
            :src="logo"
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- menu account -->
      <v-menu offset-y v-if="auth.login">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :x-large="$vuetify.breakpoint.smAndUp"
            v-bind="attrs"
            v-on="on"
            class="mr-0"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list dense min-width="250px" max-height="250px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Hola!</v-list-item-title>
              <v-list-item-subtitle>{{ auth.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="#">
            <v-icon>mdi-account-edit</v-icon>
            <v-list-item-title>Mi cuenta</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$store.dispatch('auth/logout')">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-else
        icon
        :x-large="$vuetify.breakpoint.smAndUp"
        class="mr-0"
        to="/login"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
      <!-- end menu account -->
      <v-btn icon :x-large="$vuetify.breakpoint.smAndUp" @click="drawerCart=!drawerCart">
        <v-badge
          :content="count"
          :value="count"
          color="primary"
          overlap
        >
          <v-icon>mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="pt-0">
      <router-view></router-view>
    </v-main>

    <v-footer>
      <v-container>
        <v-card
          flat
          tile
          class="text-center transparent"
          width="100%"
        >
          <v-card-text class="pt-0">
            <template v-for="(social, j) in socialNetworking">  
              <v-btn
                :key="j"
                class="mx-2"
                icon
                target="_blank"
                :href="social.link"
              >
                <v-icon size="24px">
                  {{ social.icon }}
                </v-icon>
              </v-btn>
            </template>
          </v-card-text>

          <v-card-text class="pt-0">
            El consumo excesivo de alcohol es dañino para la salud. Venta prohibido a menores de 18 años de edad.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pb-0">
            {{ new Date().getFullYear() }} — <strong>NegociosPotosí</strong>
          </v-card-text>
        </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
    name: 'App',
    data() {
        return {
            classNavbarBg: 'bg-gradient',
            drawer: null,
            drawerCart: null,
            group: null,
            logo: '',
            socialNetworking: []
        }
    },
    computed: {
        auth () {
            return this.$store.state.auth;
        },
        count () {
            return this.$store.state.cart.count;
        },
        cart () {
            return this.$store.state.cart.cart;
        },
        total () {
            return this.$store.state.cart.total;
        }
    },
    watch: {
        group () {
            this.drawer = false
        },
        // count () {
        //   this.drawerCart = !this.drawerCart
        // }
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
        this.getLogo()
        // this.$vuetify.theme.dark = (localStorage.getItem('mode')=='dark')?true:false
    },
    methods: {
        /**
         * mode
         */
        changeMode() {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            localStorage.setItem('mode', (this.$vuetify.theme.dark)?'dark':'light')
            this.$vuetify.theme.dark = (localStorage.getItem('mode')=='dark')?true:false
        },
        changeColor() {
            if (
                document.body.scrollTop > 10 ||
                document.documentElement.scrollTop > 10
            ) {
                this.classNavbarBg = '';
            } else {
                this.classNavbarBg = 'bg-gradient';
            }
        },
        /**
         * logo
         */
        async getLogo() {
            await axios.post('get-logo-rrss', {})
            .then((response) => {
                // console.log(response)
                this.logo = response.data[0]
                response.data[1].forEach(element => {
                    this.socialNetworking.push(JSON.parse(element.content))
                });
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },
};
</script>
<style lang="scss">
.bg-gradient {
    background: linear-gradient(180deg, #272727, transparent) !important;
}
</style>
